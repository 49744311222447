.main_page {
  width: 100vw;
  height: 95vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.icon_menu {
  width: 150px;
  height: 200px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.svg_icon {
  width: 150px;
  height: 150px;
  fill: white;
}

.main_container {
  width: 225px;
  height: 225px;
  border: 1px solid white;
  position: relative;
}

.name_album {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  width: 80%;
  text-align: center;
  background: rgba(17, 17, 17, 0.596);
  transition: all 0.2s ease-in-out;
  z-index: 5;
}

.main_container:hover .name_album {
  width: 100%;
}

.main_container:hover img {
  filter: brightness(150%);
}

.main_container {
  width: 100vw;
  height: 95vh;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
}

.message_box {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;
  z-index: 10000;
  background: rgb(44, 44, 44);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.close_icon > span:first-child {
  width: 100%;
  height: 10px;
  background: #000;
  display: block;
  transform: translateY(100%) rotate(45deg);
}

.close_icon > span:last-child {
  width: 100%;
  height: 10px;
  background: #000;
  display: block;
  transform: rotate(135deg);
}

.main_container p {
  color: #fff;
}

.section_create_school_year {
  width: 33%;
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
}

.section_create_album {
  width: 33%;
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
}

.section_add_photo_to_album {
  width: 34%;
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
}

.div_form {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Input style */

.input_text {
  width: 90%;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  color: #000;
}

select {
  width: 90%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  color: #000;
}

.input_file::-webkit-file-upload-button {
  visibility: hidden;
}
.input_file::before {
  content: "Wybierz zdjęcie";
  display: inline-block;
  background: linear-gradient(to left, #fffffe, #1b70df);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  color: black;
  margin-left: 25%;
}
.input_file:hover::before {
  border-color: rgb(59, 59, 59);
}
.input_file:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.select_style {
  /* Default style */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* Delete basic arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";

  /* Arrow */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.select_style:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

/* Button style */

.button_style {
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

/* Media query */

@media only screen and (max-width: 1000px) {
  .main_container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 95vh;
  }

  .section_create_school_year,
  .section_create_album,
  .section_add_photo_to_album {
    width: 100vw;
    height: auto;
    overflow: visible;
  }

  .button_style {
    margin-bottom: 15px;
  }
}

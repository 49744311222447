.layout {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background: url("../../public/tlo.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
}

.main_container {
  height: 95vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div_form {
  position: relative;
  width: 600px;
  height: 500px;
  border: 1px solid white;
  overflow: hidden;
}

.form {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  inset: 10px;
  z-index: 2;
  background: rgb(24, 24, 24);
}

.form label {
  color: #fff;
}

.form > div > input {
  width: 90%;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  color: #000;
}

.login_button {
  width: 50%;
  height: 50px;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  color: #000;
}

.div_form::after {
  content: "";
  width: 50%;
  height: 200%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  background: rgb(255, 255, 255);
  transform: rotate(30deg);
  animation: border_animation 5s infinite linear;
  z-index: 1;
}

@keyframes border_animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error_div {
  width: 30%;
  height: 50px;
  margin-top: 30px;
  background: rgb(155, 8, 8);
  border-radius: 5px;
  text-align: center;
}

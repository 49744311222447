/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&display=swap");
@font-face {
  font-family: logo-font;
  src: url("../fonts/Oswald-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: logo-font-fat;
  src: url("../fonts/Oswald-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: logo-font-reg;
  src: url("../fonts/Oswald-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary_clr) #ddd;
  scroll-behavior: smooth;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--primary_clr);
}
*::-webkit-scrollbar-track {
  background-color: #ddd;
}
:root {
  --primary_clr: #022e5d;
  --dark_primary_clr: #033973;
  --secondary_clr: #0252a8;
}
*::-moz-selection {
  color: white;
  background: var(--dark_primary_clr);
}
*::selection {
  color: white;
  background: var(--dark_primary_clr);
}
body {
  margin: 0;
  font-family: "Oxygen", "Open Sans", sans-serif;
  min-height: -webkit-fill-available;
  min-height: 100vh;
  position: relative;
  /* overflow-x: hidden; */
  contain: paint;
}
html {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
body::after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  animation: loading 1.5s ease;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}
#root {
  min-height: 100vh;
  /* min-height: -webkit-fill-available; */
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
}
@keyframes loading {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* ---------------NAVBAR--------------- */
.logo_container {
  display: flex;
  align-items: center;
  color: var(--primary_clr);
  padding: 0px 10px;
  padding-bottom: 0;
  width: 30%;
}
.logo_container img {
  height: 100px;
  width: auto;
  margin: 0 10px;
  padding: 5px 0px;
}
.logo_container h1 {
  margin: 0;
  text-align: center;
  font-size: calc(0.25rem + 0.9vw);
  text-align: left;
  margin: 0 10px;
  font-family: logo-font-reg;
  font-weight: 500;
}
.logo-link {
  display: flex;
}
.contact_nav_container {
  width: calc(70% - 20px);
}
.upper_nav_container {
  display: flex;
}
.searchbar {
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, .4); */
}
.searchbar input[type="text"] {
  /* background-color: red; */
  border: none;
  outline: none;
  padding: 5px 5px;
  height: 20px;
}
.searchbar > button {
  display: block;
  height: 30px;
  width: 30px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--dark_primary_clr);
  transition: filter 0.2s ease;
}
.searchbar > button:hover {
  filter: brightness(1.5);
}
.searchbar > button img {
  /* background-color: var(--secondary_clr); */
  /* box-shadow: 0px 0px 10px var(--dark_primary_clr); */
  filter: invert(1);
}
.contact_nav_container {
  display: flex;
  flex-direction: column;
}
.contact_container {
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* font-weight: 600; */
  font-family: logo-font-reg;
  padding: 10px 10px;
  box-sizing: border-box;
}
.contact_container img {
  height: 28px;
  width: 28px;
}
@media (max-width: 1280px) {
  .contact_container img {
    height: 23px;
    width: 23px;
  }
  .contact_container {
    padding: 5px;
  }
}
.contact_container a:visited {
  color: var(--primary_clr);
}
.contact_item {
  display: flex;
  align-items: center;
  height: fit-content;
  font-size: calc(0.25rem + 0.75vw);
}
.contact_item:nth-child(5) {
  flex-direction: row-reverse;
}
.contact_item span {
  margin-left: 5px;
}
.flags {
  display: flex;
}
.flags img {
  height: 40px;
  width: auto;
  margin-right: 5px;
}
header {
  color: var(--primary_clr);
  font-family: logo-font;
  letter-spacing: 1px;
  z-index: 2;
}
.upper_nav {
  background-color: var(--primary_clr);
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(0.75rem + 0.5vw);
  padding: 15px;
  position: relative;
  text-transform: capitalize;
}
.upper_nav::after {
  content: "";
  width: 110px;
  height: 100%;
  position: absolute;
  background-color: var(--primary_clr);
  left: -100px;
  clip-path: polygon(90% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.upper_nav a,
.upper_nav .drop-down-cnt {
  position: relative;
}
.drop-down-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
.upper_nav .drop-down-content:hover,
.upper_nav .drop-down-cnt:hover .drop-down-content {
  top: 100%;
  pointer-events: all;
  opacity: 1;
}
.upper_nav .drop-down-content {
  top: 70%;
  position: absolute;
  z-index: 30;
  background-color: var(--primary_clr);
  /* padding: 10px 20px; */
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: top 0.2s ease, opacity 0.2s ease;
  opacity: 0;
  width: fit-content;
}
.upper_nav .drop-down-cnt input {
  display: none;
}
.upper_nav .drop-down-content > a {
  padding: 5px 20px;
  display: block;
  white-space: nowrap;
  width: 100%;
  /* box-sizing: border-box; */
  text-align: center;
  transition: all 0.1s ease;
}
.upper_nav .drop-down-content a:hover {
  background-color: var(--secondary_clr);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.mobile-menu-content .drop-down-cnt {
  position: relative;
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
  font-family: logo-font;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.mobile-menu-content .drop-down-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  box-sizing: border-box;
}
.mobile-menu-content .drop-down-content > a {
  display: block;
}
.trigger:checked ~ .drop-down-content {
  max-height: 500px;
}
.mobile-menu-content .drop-down-cnt input {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: none;
  opacity: 0;
  margin: 0;
}
.font-btn {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  font-family: logo-font;
  font-weight: 900;
  margin-left: 5%;
  cursor: pointer;
  font-size: calc(0.6rem + 0.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom_nav {
  background-color: var(--primary_clr);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: calc(0.5rem + 0.7vw);
  color: white;
  padding: 10px 0px;
  text-transform: capitalize;
  /* font-weight: 700; */
  font-family: logo-font-fat;
  position: sticky;
}
.bottom_nav a {
  display: block;
  width: 14.5%;
  text-align: center;
  border-right: 1px solid var(--secondary_clr);
  position: relative;
  z-index: 1;
  transition: color 0.35s ease;
  box-sizing: border-box;
  border-top: 1px solid var(--primary_clr);
}
.bottom_nav a:last-child {
  border-right: none;
}
.active-page-btn::after,
.bottom_nav a::after,
.more-news-cnt > a::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: calc(100% + 20px);
  width: 100%;
  bottom: calc(0% - 10px);
  left: 0;
  background-color: white;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.2s ease 0.2s;
}
.bottom_nav a:hover::after,
.bottom_nav a:hover::before,
.more-news-cnt > a:hover::after,
.more-news-cnt > a:hover::before {
  transform: scaleY(1);
}
.active-page-btn::before,
.bottom_nav a::before,
.more-news-cnt > a::before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 15%;
  width: 100%;
  bottom: calc(0% - 10px);
  left: 0;
  background-color: var(--secondary_clr);
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.2s ease;
}
.bottom_nav a:hover::before,
.more-news-cnt > a:hover::before {
  transition: transform 0.25s ease 0.25s;
}
.bottom_nav a:hover::after,
.more-news-cnt > a:hover::after {
  transition: transform 0.2s ease;
}
.more-news-cnt > a:hover,
.bottom_nav a:hover {
  color: var(--primary_clr) !important;
}
.active-page-btn::before,
.active-page-btn::after {
  transform: scaleY(1) !important;
}
.active-page-btn {
  color: var(--primary_clr) !important;
}
.upper_nav a:visited,
.upper_nav a,
.bottom_nav a:visited,
.bottom_nav a {
  color: white;
}
.contact_container a:visited,
.contact_container a {
  color: var(--primary_clr);
}
.mobile_menu {
  display: none;
  box-sizing: border-box;
  overflow: hidden;
}
.mobile-toggle-btn-cnt {
  display: none;
}
/* ---------------NAVBAR-MOBILE--------------- */
.mobile-toggle-btn {
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-toggle-btn:hover {
  cursor: pointer;
}
.mobile-toggle-bars {
  width: 90%;
  height: 60%;
  position: relative;
}
.mobile-toggle-bars > div {
  width: 100%;
  height: 10%;
  background-color: white;
  /* clip-path: polygon(10% 0%, 0% 100%, 90% 100%, 100% 0%); */
  transition: 0.3s ease;
  position: absolute;
}
.active-btn .mobile-toggle-bars > div:nth-child(2) {
  transform: translateX(100%) translateY(-50%) skew(-50deg);
  opacity: 0;
}
.mobile-toggle-bars > div:nth-child(2) {
  top: 50%;
  transform: translateY(-50%) skew(-50deg);
}
.mobile-toggle-bars > div:nth-child(1) {
  top: 0;
  transform: skew(-50deg);
}
.mobile-toggle-bars > div:nth-child(3) {
  bottom: 0;
  transform: skew(-50deg);
}
.active-btn .mobile-toggle-bars > div:nth-child(1) {
  top: 45%;
  transform: rotate(45deg) skew(-50deg);
}
.active-btn .mobile-toggle-bars > div:nth-child(3) {
  bottom: 45%;
  transform: rotate(-45deg) skew(-50deg);
}
.mobile_menu a {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 1.5rem;
  text-transform: capitalize;
  /* border-bottom: 2px solid white; */
  position: relative;
  box-sizing: border-box;
}
.mobile_menu .drop-down-title::before {
  content: "";
  position: absolute;
  right: 20px;
  top: 1.25rem;
  width: 15px;
  height: 15px;
  background-color: white;
  transform: rotate(135deg);
  transition: 0.3s ease;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  pointer-events: none;
}
.trigger:checked ~ .drop-down-title::before {
  transform: rotate(135deg);
}
.trigger:checked ~ .drop-down-title::after {
}
.mobile_menu .drop-down-title::after,
.mobile-menu-content > a::after {
  content: "";
  position: absolute;
  width: 5rem;
  height: 3px;
  left: calc(50% - 2.5rem);
  bottom: 0;
  background-color: white;
  transform: skew(-45deg);
}
.functions-cnt {
  position: absolute;
  height: 50px;
  left: 0;
  bottom: 0;
  background-color: var(--primary_clr);
  width: 100%;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  z-index: 1;
  overflow: hidden;
}
.functions-cnt::before,
.functions-cnt::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 100%;
  background-color: var(--primary_clr);
  bottom: 0;
  z-index: -1;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.6));
}
.functions-cnt::after {
  right: -50px;
  transform: skew(-45deg);
}
.functions-cnt::before {
  left: -50px;
  transform: skew(-45deg);
}
.functions-cnt > button > img {
  height: 80%;
  width: 80%;
  filter: invert(1);
}
.mobile-font-change {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 75px;
  margin-right: 5%;
}
.mobile-font-change > button {
  font-size: 1.75rem !important;
  color: white !important;
  font-weight: normal;
}
@media (max-width: 1100px) {
  .menu {
    position: relative;
  }
  .menu::after {
    content: "";
    position: absolute;
    width: 60%;
    height: 100%;
    background-color: var(--primary_clr);
    top: 0;
    right: 0;
    z-index: -1;
  }
  .menu::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 100%;
    background-color: var(--primary_clr);
    top: 0%;
    right: 59%;
    z-index: -1;
    clip-path: polygon(90% 0%, 100% 0%, 100% 101%, 0% 101%);
  }
  .upper_nav_container {
    justify-content: space-between;
    align-items: center;
  }
  .contact_nav_container {
    display: none;
  }
  .bottom_nav_container {
    display: none;
  }
  .mobile-toggle-btn-cnt {
    display: block;
    margin: 0px 10px;
    width: calc(4vw + 20px);
    height: calc(4vw + 20px);
    /* background-color: red; */
  }
  .mobile-toggle-btn {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .mobile_menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    transform: translateX(0%);
    z-index: 10;
    /* height: calc(100vh - (5vh + 30px)); */
    height: calc(100vh - 100%);
    min-width: 100vw;
    /* background-color: var(--primary_clr); */
    transition: transform 0.2s ease;
    background-image: url("./bg2.png");
  }
  .mobile-menu-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    padding-bottom: 70px;
  }
  .mobile-menu-content::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    box-shadow: 0px 0px 5px black;
    width: 100%;
    height: 1px;
  }
  .disabled-menu {
    /* display: none; */
    transform: translateX(-100%);
  }
  .logo_container img {
    height: calc(4vw + 30px);
    width: auto;
    margin: 0 0.5vw;
    padding: 5px 0;
  }
  header,
  footer {
    box-shadow: 0px 0px 15px black;
  }
  .logo_container h1 {
    display: none;
  }
  .first_page_news .news-date-cnt {
    font-size: calc(0.6rem + 0.4vw) !important;
  }
}
@media (max-width: 1100px) and (orientation: portrait) {
  /* ---------------SLIDER--------------- */
  .slide-content > img {
    height: 100vh;
  }
}
/* ---------------------------VERY SMALL SCREEN SIZE--------------------------- */
@media (max-width: 400px) {
  /* footer{
    transform: translateY(100%);
    position: absolute !important;
    bottom: 0;
  } */
  /* Działa z losowym powodzeniem w mobilnym safari */
  .slide-logo > img {
    width: calc(155px + 5vw) !important;
    height: auto;
  }
  .slide-logo > h1 {
    font-size: 1.525rem;
  }
}
@media (max-width: 1400px) {
  .slide-logo > img {
    width: calc(125px + 6.5vw) !important;
  }
}
/* ---------------Main + MainSlider--------------- */
main {
  display: flex;
  flex-direction: column;
  position: relative;
}
main .swiper-container {
  position: absolute;
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100%;
}
.slide-content {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.slide-content > img {
  object-fit: cover;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}
.first-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slide-logo {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  filter: drop-shadow(0px 0px 5px black);
  font-family: logo-font;
  letter-spacing: 1px;
}
/* .slide-logo::before{
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: -1;
  background-color: rgba(255, 255, 255, .3);
  padding: 20px 0px;
} */
.slide-logo > img {
  width: calc(175px + 5vw);
  height: auto;
}
.slide-logo > h1 {
  cursor: default;
}
.first-slide::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.swiper-button-prev,
.swiper-button-next {
  color: var(--primary_clr);
}
/* ---------------Footer--------------- */
footer {
  width: 100%;
  background-color: var(--primary_clr);
  padding: 10px 0px;
  z-index: 1;
  position: relative;
}
footer::before {
  content: "Współpracujemy z:";
  color: white;
  position: absolute;
  top: -30%;
  font-family: logo-font;
  background-color: var(--primary_clr);
  height: 30%;
  width: 140px;
  line-height: 25px;
  text-align: left;
  padding: 2px 0px 5px 5px;
  font-weight: 500;
  letter-spacing: 2px;
}
footer::after {
  content: "";
  position: absolute;
  left: 145px;
  top: -30%;
  height: 31%;
  width: 30px;
  background-color: var(--primary_clr);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
footer .swiper-container::after,
footer .swiper-container::before {
  content: "";
  position: absolute;
  top: 0;
  width: 5%;
  height: 100%;
  z-index: 2;
}
footer .swiper-button-prev,
footer .swiper-button-next {
  color: var(--secondary_clr);
}
footer .swiper-container::before {
  background-image: linear-gradient(90deg, var(--primary_clr) 30%, transparent);
  left: 0px;
}
footer .swiper-container::after {
  background-image: linear-gradient(
    -90deg,
    var(--primary_clr) 30%,
    transparent
  );
  right: 0;
}
footer .swiper-slide img {
  height: 50px;
  width: 90px;
}
footer .footer-btn-cnt {
  position: absolute;
  top: -25px;
  right: 0;
  min-height: 25px;
  letter-spacing: 2px;
  display: flex;
  width: fit-content;
  flex-direction: row;
  overflow: hidden;
  padding-left: 30px;
}
footer .footer-btn-cnt > a {
  display: block;
  position: relative;
  color: white;
  font-family: logo-font;
  background-color: var(--primary_clr);
  padding: 0px 10px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
  padding-right: 2rem;
}
footer .footer-btn-cnt > a:last-child {
  padding-right: 10px;
}
footer .footer-btn-cnt > a:first-child {
  filter: none;
}
footer .footer-btn-cnt > a::before {
  content: "";
  position: absolute;
  width: 31px;
  height: 101%;
  background-color: var(--primary_clr);
  left: -30px;
  /* top: 0; */
  clip-path: polygon(100% -2%, 100% 100%, 0% 100%);
}
@media (max-width: 768px) {
  .footer-login-btn,
  .footer-map-btn {
    display: none !important;
  }
  footer .footer-btn-cnt > a {
    filter: none;
  }
}

/* -------------------------SCHOOL PAGE TEMPLATE-------------------------*/
/* .school-page-cnt{
  position: relative;
  width: 100%;
  min-height: inherit;
  background-image: url('./bg.png');
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-around;
  flex-direction: row;
  padding: 30px 0;
}
.school-page-cnt>div{
  height: 200px;
  width: 200px;
  background-color: var(--primary_clr);
  margin: 1%;
} */
.school-page-cnt {
  position: relative;
  width: 100%;
  min-height: inherit;
  /* background-image: url('./bg.png'); */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, 225px);
  grid-template-rows: repeat(auto-fit, 225px);
  place-content: center;
  padding: 10% 0%;
  box-sizing: border-box;
}
/* -------------------------SCHOOL BLOCK TEMPLATE-------------------------*/
.sch-block {
  width: 225px;
  height: 225px;
  background-color: var(--primary_clr);
  box-shadow: 0px 0px 15px #444;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}
.sch-block::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 225px solid transparent;
  border-top: 225px solid transparent;
  border-bottom: 225px solid var(--dark_primary_clr);
  /* #064385 */
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
  z-index: -1;
  transition: all 0.2s ease;
}
.sch-block-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: white;
  font-family: logo-font;
}
.sch-block-content img {
  width: 120px;
  height: 120px;
  /* filter: invert(22%) sepia(33%) saturate(5041%) hue-rotate(199deg) brightness(95%) contrast(99%); */
  filter: invert(1);
  transition: all 0.2s ease;
}
.sch-block-content p {
  margin: 0;
  font-size: 24px;
}
.sch-block a {
  position: absolute;
  display: block;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.school-page-cnt::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("./bg.png");
}
.sch-block:hover .sch-block-content img {
  width: 135px;
  height: 135px;
}
.sch-block:hover::after {
  border-bottom: 300px solid var(--dark_primary_clr);
}
/* -----------------NEWS----------------- */
.news-container {
  position: relative;
  width: 100%;
  min-height: inherit;
  background-image: url("./bg.png");
  display: flex;
  flex-direction: column;
}
.news-select {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.news-select > h1 {
  text-align: center;
  text-transform: capitalize;
  font-family: logo-font-reg;
  font-weight: 400;
  position: relative;
  z-index: 1;
  color: white;
  padding: 0px 10px;
  box-sizing: border-box;
}
.news-select > h1::after {
  content: "";
  position: absolute;
  width: 140%;
  height: 110%;
  background-color: var(--primary_clr);
  transform: skew(-45deg);
  z-index: -1;
  left: -20%;
  top: -5%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.news-select-cnt {
  width: 50%;
  display: flex;
  justify-content: center;
}
.news-container input,
.news-select-cnt select {
  font-family: logo-font;
  background-color: var(--primary_clr);
  color: white;
  padding: 5px;
  outline: none;
  border: none;
  font-size: 1rem;
}
/* .news-select-cnt select{
  margin-right: 15px;
} */
.news-select-cnt select option {
  padding: 5px 10px;
}
/* ----------------- MAIN-NEWS----------------- */
.first_page_news_cnt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('./bg.png'); */
  position: relative;
  z-index: 1;
}
.first_page_news_cnt::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("./bg.png");
}
.first_page_news {
  width: 90%;
  height: 90%;
  background-color: #eee;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
}
.news-title-cnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.news-title-cnt h2 {
  margin: 0;
  font-family: logo-font;
}
.news-date-cnt {
  width: calc(60px + 1vw);
  height: calc(60px + 1vw);
  padding: 5px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-family: "Oxygen", "Open Sans", sans-serif;
  background-color: var(--primary_clr);
  font-size: calc(0.5rem + 0.5vw);
}
.news-title-cnt::after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: 5px;
  margin: 0px 10px;
  background-color: var(--primary_clr);
  left: 0;
  bottom: -10%;
}
.first-page-news-content {
  height: calc(100% - 100px);
  width: calc(100% - 20px);
  margin: 0 10px;
  display: flex;
  overflow: hidden;
}
.first-page-news-img {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.first-page-news-img > img {
  width: 300px;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin-top: 1em;
  max-height: 100%;
}
.first-page-news-text {
  width: 70%;
  overflow: auto;
  position: relative;
}
.first-page-news-text::-webkit-scrollbar {
  display: none;
}
.first-page-news-text::before,
.first-page-news-text::after {
  content: "";
  position: sticky;
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  box-shadow: 0px 0px 10px 10px #eee;
  background-color: #eee;
}
.first-page-news-text::before {
  top: -1px;
}
.first-page-news-text::after {
  bottom: 0px;
  transform: rotate(180deg);
}
@media (max-width: 1250px) {
  .first-page-news-img > img {
    width: 200px;
  }
}
@media (max-width: 930px) {
  .first-page-news-content {
    flex-direction: column;
  }
  .first-page-news-content > div {
    width: 100%;
  }
  .first-page-news-text {
    margin-top: 1em;
  }
  .first-page-news-text > h3 {
    margin-top: 10px;
  }
}
@media (max-width: 650px) {
  .first-page-news-img > img {
    width: 150px;
  }
  .news-title-cnt h2 {
    font-size: calc(0.75rem + 1.75vw);
  }
  .more-news-cnt {
    justify-content: center !important;
  }
}
.more-news-cnt {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
}
.more-news-cnt > a {
  background-color: var(--primary_clr);
  padding: 5px 10px;
  box-sizing: border-box;
  color: white;
  font-family: logo-font;
  font-size: 1.25rem;
  position: relative;
  z-index: 1;
  transition: color 0.35s ease;
}
.more-news-cnt:hover > a::after {
  height: 100%;
  bottom: 0;
}
.more-news-cnt:hover > a::before {
  bottom: 0;
  height: 10%;
}
.more-news-cnt > a::after {
  height: 100%;
  bottom: 0;
}
.more-news-cnt > a::before {
  bottom: 0;
  height: 10%;
}
.read-more-cnt,
.main-content-cnt {
  position: relative;
  width: 100%;
  min-height: inherit;
  background-image: url("bg.png");
  display: flex;
  justify-content: center;
  padding: 3% 0%;
  box-sizing: border-box;
}
.main-content-cnt {
  align-items: center;
  flex-direction: column;
}
.read-more-cnt {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  padding-bottom: 2%;
}
.return-btn-cnt {
  width: calc(90% + 50px);
  display: flex;
  justify-content: flex-start;
  padding: 10px 5px;
  box-sizing: border-box;
}
@media (max-width: 460px) {
  .return-btn-cnt {
    justify-content: center;
  }
}
.return-btn {
  background-color: var(--primary_clr);
  border-radius: 0;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  font-family: logo-font;
  font-size: 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}
.read-more-cnt .content-cnt {
  flex: 1 1;
}
.main-content-cnt .content-cnt {
  flex: 1;
}
.content-cnt {
  width: 90%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}
.content-cnt table {
  border-collapse: collapse;
  text-align: center;
}
.content-cnt table td {
  padding: 5px;
}
.news-cnt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  box-sizing: border-box;
}
.news-item {
  width: 70%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  min-height: 500px;
  margin-bottom: 50px;
  padding: 20px 10px;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}
.news-item .news-date-cnt {
  width: calc(50px + 0.75vw);
  height: calc(50px + 0.75vw);
  font-family: logo-font;
  font-size: calc(0.5rem + 0.3vw);
}
.news-item h3 {
  margin: 0;
  font-family: logo-font;
}
.news-item-content {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  flex: 1 1;
}
@media (max-width: 1500px) {
  .news-item {
    width: 80%;
  }
}
@media (max-width: 1100px) {
  .news-item {
    width: 90%;
  }
  .news-item-content {
    flex-direction: column;
    align-items: center;
  }
  .news-item-text {
    width: 100% !important;
    padding: 1%;
  }
}
@media (max-width: 400px) {
  .news-image img {
    width: 200px !important;
  }
}
@media (max-width: 260px) {
  .news-image img {
    width: 100% !important;
    /* box-shadow: 0px 0px 20px red; */
  }
}
.pagination {
  display: flex;
  flex-direction: row;
  margin: 50px 0px;
  width: 70%;
  justify-content: space-between;
  padding: 10px 20px;
  list-style: none;
  font-family: logo-font;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  max-width: 400px;
}
.next,
.previous,
.page,
.page-break {
  cursor: pointer;
}
.page {
  height: 25px;
  min-width: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s ease;
}
.page:hover {
  background-color: rgba(2, 46, 93, 0.7);
}
.disabled {
  display: none !important;
}
.pagination .selected {
  background-color: var(--primary_clr);
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.page > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.news-image {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-image img {
  width: 300px;
  height: auto;
}
.no-img-news {
  filter: grayscale(1);
  opacity: 0.25;
}
.news-item-text {
  width: 60%;
  height: 100%;
}
.news-title-cnt h3 {
  font-family: logo-font-reg;
  font-weight: 400;
}
option {
  font: -moz-pull-down-menu;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background-image: url("./down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  padding-right: 30px !important;
  background-position-x: 95%;
  background-position-y: 60%;
}
.first_page_news .no-img-news {
  box-shadow: none;
}
.dropdown-menu-cnt {
  position: absolute;
  height: fit-content;
  min-width: 265px;
  left: 0;
  top: 0;
  z-index: 1;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  transition: transform 0.2s ease;
}
.dropdown-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--primary_clr);
  text-align: center;
  padding: 20px 0px;
  font-family: logo-font;
  box-sizing: border-box;
  position: relative;
  width: 225px;
}
.dropdown-menu a {
  color: white;
  padding: 5px 0px;
}
.dropdown-btn {
  position: sticky;
  width: 40px;
  height: 40px;
  top: 0;
  right: -20px;
  background-color: var(--primary_clr);
  outline: none;
  border: none;
  border-radius: 0px 2px 2px 0px;
  font-family: logo-font;
  color: white;
  font-size: 25px;
  padding: 0;
}
.dropdown-btn::after {
  content: ">";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.fold {
  /* left: -230px; */
  /* left: -100%; */
  transform: translateX(calc(-100% + 52px + 1rem));
}
@media (max-width: 1100px) {
  .dropdown-menu-select {
    display: block;
  }
  .dropdown-menu-cnt {
    display: none;
  }
}
@media (min-width: 1100px) {
  .dropdown-menu-select {
    display: none;
  }
}
.dropdown-menu-select > select {
  margin: 10px 15px;
  box-sizing: border-box;
  background-color: var(--primary_clr);
  color: white;
  border: none;
  outline: none;
  padding: 10px 15px;
}
.title {
  writing-mode: vertical-rl;
  text-align: center;
  padding: 20px 2px;
  background-color: var(--primary_clr);
  color: white;
  font-family: "logo-font";
  font-size: 1rem;
}
.cookie-cnt {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0% 10%;
  box-sizing: border-box;
}
.cookie-info {
  max-width: 700px;
  min-height: 150px;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  font-family: "logo-font-reg";
  position: relative;
  overflow: hidden;
}
.cookie-info button {
  padding: 10px 20px;
  background-color: var(--primary_clr);
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}
.cookie-info a,
.cookie-info a:visited {
  color: var(--primary_clr);
}
.cookie-info img {
  position: absolute;
  height: 70%;
  right: -20px;
  top: -20px;
  opacity: 0.2;
  transform: rotate(180deg);
}
.map-ul a,
.map-li a {
  color: black;
  font-family: logo-font-reg;
}
.map-li {
  margin-left: 10%;
}
.map-ul {
  margin-left: 5%;
  padding: 0;
}

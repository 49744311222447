.main_container {
  width: 100%;
  height: 50vh;
  overflow: auto;
}

.button_div {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lato&family=Nunito&family=Playfair+Display:ital@1&family=Prata&family=Raleway:ital,wght@1,100&family=Roboto&family=Roboto+Condensed&family=Teko&display=swap");

.btn {
  font-family: Roboto, sans-serif;
  font-weight: 0;
  font-size: 14px;
  color: #fff;
  background-color: #0066cc;
  padding: 10px 30px;
  border: 2px solid #0066cc;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.btn:hover {
  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(-0px);
  background-color: #fff;
  color: #0066cc;
  border: solid 2px #0066cc;
}

.content_container {
  width: 100%;
  height: 90%;
  color: #fff;
  overflow: auto;
}

@media only screen and (max-width: 1000px) {
  .main_container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 95vh;
    width: 100%;
  }

  .button_div {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .content_container {
    float: left;
  }
}

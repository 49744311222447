.slider_div {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.slider_div > img {
  width: 50%;
  height: 100%;
}

/* Array div */
.right_array {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50px;
  height: 50px;
}

.left_array > button,
.right_array > button {
  background: transparent;
  border: none;
}

.left_array {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50px;
  height: 50px;
  z-index: 1000;
}

.download_button {
  background: transparent;
  border: none;
  padding: 15px;
}

.play_button {
  background: transparent;
  border: none;
  padding: 15px;
  cursor: pointer;
}

.buttons_div {
  float: left;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 1000px) {
  .slider_div > img {
    width: 100%;
    height: 100%;
  }
}

.arrow_style {
  width: 50px;
  height: 50px;
  fill: white;
  cursor: pointer;
}

.download {
  width: 16px;
  height: 16px;
  fill: white;
  cursor: pointer;
}

.other_buttons {
  width: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
}
